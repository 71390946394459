<template>
    <div :class="disabled ? 'disabled': ''">
        <multiselect
            :disabled='disabled'
            :options='options'
            v-model='user'
            label='name'
            @select='onSelect'
            placeholder='Select a user'
        />
    </div>
</template>

<script>
    import { users } from '@/util/apiRequests';

    export default {
        name: 'FormUser',
        props: {
            disabled: {
                type: Boolean,
                default: false,
            },
            userOptions: {
                type: Array,
            },
            user_id: {
                type: [Number, String],
            },
            modifyRecord: {
                type: Function,
                required: true
            },
            modifyMetaData: {
                type: Function,
                required: true
            },
            keyToModifyBy:{
                type: String,
                default: 'user_id'
            },
            metaKeyToModifyBy: {
                type: String,
                default: 'user'
            },
            useApiToRetrieveOptions: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                options: [],
                user: {}
            };
        },
        methods: {
            async getOptions() {
                // let res = await this.$api.get(users.getActiveUsers());
                const body = {
                    criteria: {
                        page: { num_per_page: '10000', page_num: 1 }
                    }
                };
                let res = await this.$api.post('/users/list', body);
                let options = res.data.rows;
                this.options = options;
            },
            onSelect(option) {

                this.modifyRecord(this.keyToModifyBy, option.id);
                if(this.modifyMetaData) {
                    this.modifyMetaData(this.metaKeyToModifyBy, option);
                }
            },
        },
        async created() {
            if (this.useApiToRetrieveOptions) {
                await this.getOptions();
            } else {
                this.options = this.userOptions;
            }
            if(this.user_id) {
                this.user = this.options.find(user => user.id === this.user_id);
                this.onSelect(this.user);
            }
        }
    };
</script>

<style scoped>

</style>