<template>
    <div :class="disabled ? 'disabled' : ''">
        <div class="dark-text header-w-bg" v-if='showServiceLabel'>Service</div>
        <div v-if="service_codes && service_codes.length" class="bordered">
            <div
                v-for="(service_code, key) in service_codes"
                :key="service_code.data_id"
                class="flex center single-service margin-0 has-primary-label"
            >
                <div class="multiselect bottom-15">
                    <div class="flex center">
                        <label class="right-5 bottom-0" :for="'radio_' + service_code.data_id">{{
                                service_code.primary ? 'Primary' : ''
                            }}</label>
                        <input class="color"
                            type="radio"
                            name="service_code"
                            :value="service_code.data_id"
                            v-model="service_model"
                            :id="'radio_' + service_code.data_id"
                        />
                        <Multiselect
                            class="max800 color"

                            v-model="service_code.selected"
                            :options="options"
                            :key="'select_' + service_code.data_id"
                            track-by="id"
                            :custom-label="serviceCodeDesc"
                            @select="
                                            (e) => {
                                                modifyMenu(e, service_code.selected, 'id')
                                            }
                                        "
                            @input="() => {$forceUpdate()}"
                            :disabled='disabled'

                        >
                        </Multiselect>
                        <button class="delete-icon" type='button' @click.prevent="remove_service_code(key)" v-if='isRemoveable'>
                                        <span class="button material-icons-outlined"
                                        >delete</span
                                        >
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <button class="add-service_btn no-bg dark-text add top-10" @click.prevent="add_service()" v-if='isAddable' type='button'>
            <span class="material-icons purple">add_box</span> Add additional Service
        </button>
    </div>
</template>

<script>
    import { calibrateMenu, calibrateMenuToDisabledStatus, generateId } from '@/util/genericUtilityFunctions';
    import { serviceFees } from '@/util/apiRequests';


    export default {
        name: 'ServiceList',
        props: {
            keyToModifyBy:{
                type: String,
                default: 'service_codes'
            },
            metaKeyToModifyBy: {
                type: String,
                default: 'service_code_options'
            },
            showService: {
                type: Boolean,
                default: true
            },
            showServiceLabel: {
                type: Boolean,
                default: true
            },
            maxCountOfServiceCodes: {
                type: Number,
            },
            maxDefaultToStartWith: {
                type: Number,
                default: 1
            },
            service_codes: {
                type: Array,
                // default: function() {
                //     return [];
                // }
            },
            modifyRecord: {
                type: Function,
                required: true
            },
            modifyMeta: {
                type: Function,
                required: true
            },
            //this will use the api to get the list of Service options, instead of passing it from the parent component
            useApiToRetrieveOptions: {
                type: Boolean,
                default: false
            },
            service_options: {
                type: Array,
                default: () => []
            },
            isRemoveable: {
                type: Boolean,
                default: true
            },
            isAddable: {
                type: Boolean,
                default: true
            },
            disabled: {
                type: Boolean,
                default: false
            },
            client_id: {
                type: [String, Number]
            }


        },
        data() {
            return {
                options: [],
                calibrateMenu,
                calibrateMenuToDisabledStatus,
                generateId,
            }
        },
        methods: {
            modifyMenu(e, service_code, key) {
                if(service_code){

                    this.options = calibrateMenuToDisabledStatus(e, service_code, key, this.options);
                }
            },
            removeOptionsFromMenu() {

                this.options = this.options.map((element) => {
                    if (this.service_codes.some((dx) => element.id == dx.selected?.id)) {
                        element.$isDisabled = true;
                    }
                    return element;
                });
            },
            serviceCodeDesc({ service_code, long_descrip }) {
                if (service_code && long_descrip) {
                    return `${service_code} ${long_descrip}`;
                }
                return 'Select an option';
            },
            add_service() {
                if(this.modifyRecord) {
                    let itemToAdd = {
                        data_id: generateId(),
                        selected: {},
                        primary: false,
                    };
                    if(this.service_codes.length <= 0){
                        itemToAdd.primary = true;
                    }
                    let arrPlusItem = [...this.service_codes, itemToAdd];

                    this.modifyRecord(this.keyToModifyBy, arrPlusItem);
                }
            },
            generateSeveralServiceCodes(count) {
                let arr = [];
                for (let i = 0; i < count; i++) {
                    if(i == 0) {
                        arr.push({
                            data_id: generateId(),
                            selected: {},
                            primary: true,
                        });
                    } else {
                        arr.push({
                            data_id: generateId(),
                            selected: {},
                            primary: false,
                        });
                    }
                }
                return arr;
            },
            remove_service_code(index) {
                if(this.modifyRecord) {
                    let arrMinusItem = [...this.service_codes];
                    let copy = this.service_codes[index];
                    if(copy.primary == true && arrMinusItem.length > 1){
                        arrMinusItem.splice(index, 1);
                        arrMinusItem[0].primary = true;
                    }else {
                        arrMinusItem.splice(index, 1);
                    }
                    this.options = this.calibrateMenuToDisabledStatus(null, copy.selected, 'id', this.options);

                    this.modifyRecord(this.keyToModifyBy, arrMinusItem);
                }
            },
            /**
             * Gets the service_fees available for client and company level
             * if a client id is provided it will get the service_fees for that client
             * when a service fee is not available on the client level, it will default to the company one
             */
            async get_service_codes() {
                let companyCodes = await this.$api.get(`/service_fees/list/company/${this.$store.state.user.company_id}`);
                let clientCodes = this.client_id ? await this.$api.get(serviceFees.getList('client', this.client_id)) : null;
                let options = [];
                //use the client codes if they exist, otherwise use the company codes
                if(this.client_id){
                    clientCodes = clientCodes?.data;
                    companyCodes = companyCodes?.data;
                    options = clientCodes.map((element) => {
                        let found = companyCodes.find((companyElement) => {
                            return companyElement.service_code_id == element.service_code_id;
                        });
                        //use the company cost since the client cost is not set, it just appears as 0
                        if(parseInt(element.data_id) === 0) {
                            element.cost = found.cost
                            // element.long_descrip = found.long_descrip;
                        }
                        return element;
                    })

                }else{
                    options = companyCodes.data.map((element) => {
                        return element;
                    })
                }


                    this.options = options.filter(
                        (fee) => fee.code_type === 'service' && (fee.source === 'CPT' || fee.source === 'HCPCS')
                    ).map((element) => {
                        return {
                            service_code: element.service_code,
                            long_descrip: element.long_descrip,
                            id: element.service_code_id,
                            //cost: element.cost,
                        };
                    });

                    //send options with cost back as meta data since cost can change and may not match up with prepopulating the vue multiselect
                    // which is why we don't want to have the property value in this.options

                    this.modifyMeta(this.metaKeyToModifyBy, options.filter(
                        (fee) => fee.code_type === 'service' && (fee.source === 'CPT' || fee.source === 'HCPCS')
                    ).map((element) => {
                        return {
                            service_code: element.service_code,
                            long_descrip: element.long_descrip,
                            id: element.service_code_id,
                            cost: element.cost,
                        };
                    }));


            },
        },
        computed: {
            service_model: {
                get() {
                    try {
                        return this.service_codes.find((d) => d.primary).data_id;
                    }catch(e) {
                        return null;
                    }
                },
                set(value) {
                    // from rec.service_codes, the primary code
                    let arr = [...this.service_codes];
                    arr = arr.map((d) => {
                        if (d.data_id === value) {
                            return { ...d, primary: true };
                        } else {
                            return { ...d, primary: false };
                        }
                    });
                    this.modifyRecord(this.keyToModifyBy, arr);
                }
            },
        },
        async created() {
            if(this.maxDefaultToStartWith && !this.service_codes || this.service_codes.length === 0) {
               this.modifyRecord(this.keyToModifyBy,  this.generateSeveralServiceCodes(this.maxDefaultToStartWith));
            }
            if(this.useApiToRetrieveOptions) {
                await this.get_service_codes();
            }
            else {
                this.options = this.service_options;
            }
            this.removeOptionsFromMenu();
        },



    };
</script>

<style scoped>

</style>