<template>
<div v-if='!loading'>
    <form @submit.prevent="updateRecord">
        <FormClient :client_id="client_id" @getClient="getClient" :show-dob='false' :show-id='false' :show-insurances='false' :show-client-name='false' :siggys="original_signature_ids.length"
                    :form_data_id="record_id"/>
        <button
            type="button"
            @click="signature_ids = []"
            v-if="signature_ids.length && original_signature_ids.length"
        >
            Edit & Create Addendum
        </button>
        <button
            v-if="original_signature_ids.length && !signature_ids.length"
            class="align-right"
            type="button"
            @click="signature_ids = original_signature_ids"
        >
            Cancel Edit
        </button>
        <div class="dark-text header-w-bg addendum" v-if="original_signature_ids.length && !signature_ids.length">
            You are now editing the original signed document to create an addendum
        </div>
    <div>
        <b>Provider Information</b>
    </div>
    <div>
        <b>Provider Name:</b> <FormUser :user_id="rec.user_id" key-to-modify-by='user_id' meta-key-to-modify-by='user' :modify-meta-data='modifyMeta' :modify-record='modifyRecord' :use-api-to-retrieve-options='true' />
    </div>
    <div>
        <b>NPI:</b> {{getMeta('user', 'npi') ? getMeta('user', 'npi') : companyNpi() }}
    </div>
    <div>
        <b>Tax ID:</b> {{ companyTaxId() }}
    </div>

    <div>
        <b>Location of Services:</b>
        <FormCompany @getCompany="getCompany" :show-phone='false' :show-company-name='false'/>
    </div>

    <div>
        <b>Patient Information</b>
    </div>
    <div class="name-id">

        <div>
            <b>Patient Name:</b> {{ client.last_name }}, {{ client.first_name }}
        </div>
        <div>
            <b>Patient Date of Birth:</b> {{ formattedDob }}
        </div>
    </div>

    <ServiceList :service_codes="rec.service_codes" :modify-record='modifyRecord' :modify-meta='modifyMeta' :use-api-to-retrieve-options='true' :max-default-to-start-with='1' key-to-modify-by='service_codes' :client_id='client_id'/>

    <DiagnosisList  :use-diagnosis-custom='true' :diag_codes='rec.diagnosis_codes' :modify-record='modifyRecord' :use-api-to-retrieve-options='true' :max-default-to-start-with='1' key-to-modify-by='diagnosis_codes' :is-addable='true' :is-removeable='true' :diag_custom='rec.diag_custom'/>

    <p class="avoid-break">
        The fee for a Primary CPT Description visit (in person or via telehealth) is Client Fee for Primary CPT Description.   Most clients will attend one psychotherapy visit per week, but the frequency of psychotherapy visits that are appropriate in your case may be more or less than once per week, depending upon your needs and changing life circumstances. Based on a fee of Client Fee for Primary CPT Description per visit, the following are expected charges of psychotherapy services:
    </p>
    <table class="simple good-faith avoid-break">
        <thead>
            <tr>
                <th>Number of Weeks</th>
                <th>Total estimated charges for 1 session per week</th>
                <th>Total estimated charges for 1 session every other week</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td>1 Week of Service</td>
                <td>{{getCurrency(this.meta.cost * 1)}}</td>
                <td>{{getCurrency(this.meta.cost * 1)}}</td>
            </tr>
            <tr>
                <td>13 Weeks of Service (Approx. 3 Months)</td>
                <td>{{getCurrency(this.meta.cost * 14)}}</td>
                <td>{{getCurrency(this.meta.cost * 8)}}</td>
            </tr>
            <tr>
                <td>26 Weeks of Service (Approx. 6 months)</td>
                <td>{{getCurrency(this.meta.cost * 26)}}</td>
                <td>{{getCurrency(this.meta.cost * 14)}}</td>
            </tr>
            <tr>
                <td>39 Weeks of Service (Approx. 9 months)</td>
                <td>{{getCurrency(this.meta.cost * 40)}}</td>
                <td>{{getCurrency(this.meta.cost * 20)}}</td>
            </tr>
            <tr>
                <td>52 Weeks of Service (Approx. 12 months)</td>
                <td>{{getCurrency(this.meta.cost * 52)}}</td>
                <td>{{getCurrency(this.meta.cost * 26)}}</td>
            </tr>
        </tbody>
    </table>
    <div class="avoid-break">
        <p>Disclaimer</p>
        <p>
        You have a right to initiate a dispute resolution process if the actual amount charged to you substantially exceeds the estimated charges stated in your Good Faith Estimate (which means $400 or more beyond the estimated charges).
        You are encouraged to speak with your provider at any time about any questions you may have regarding your treatment plan, or the information provided to you in this Good Faith Estimate.
        </p>
        <p>Date of good faith estimate: {{created_at ? dayjs.utc(created_at).format('M/D/YY') : dayjs().format('M/D/YY')}}</p>
    </div>
        <div class="align-right" v-if="!signature_ids.length">
            <button
                v-if="original_signature_ids.length && !signature_ids.length"
                class="align-right secondary right-15"
                type="button"
                @click="signature_ids = original_signature_ids"
            >
                Cancel Edit
            </button>
            <ConfirmButton class="inline-block" @click="updateRecord()" :canedit="isEditable">Save as Draft</ConfirmButton>
        </div>
        <FormSignature
            :user_id="user_id"
            :signature_ids="signature_ids"
            ref="form_signatures"
            @getSignatures="getSignatures"
            v-if="signature_ids.length > 0"
        />
        <FormSignatureButton
            :rec="rec"
            :original_rec="original_rec"
            :signatures_info="signatures_info"
            :fileName="pageMeta.component_name"
            :signature_ids="signature_ids"
            :record_id="record_id"
            @resetView="reloadData"
            @fail="fail2sign"
            @showModal="showPdfModal"
            :save="updateRecord"
        >Sign and Submit</FormSignatureButton
        >
    </form>
</div>
</template>

<script>

    import DiagnosisList from '@/components/general/Diagnosis/DiagnosisList';
    import ServiceList from '@/components/general/Service/ServiceList';
    import FormClient from '@/components/formpieces/FormClient'
    import FormCompany from '@/components/formpieces/FormCompany.vue';
    import FormSignature from '@/components/formpieces/FormSignatureNew';
    import FormSignatureButton from '@/components/formpieces/FormSignatureButtonNew';
    import { dryalex } from '@/mixins/dryalex';
    import { getCurrency } from '@/util/getCurrency';
    import { merge } from 'lodash'
    import FormUser from '@/components/formpieces/FormUser';
    import dayjs from 'dayjs';
    import { settings } from '@/util/apiRequests';

    export default {
        name: 'GoodFaithEstimateForMentalHealthServices',
        props: {
            record_id: {
                type: [String, Number],
                required: false,
                default: 0
            }
        },
        components: { FormUser, ServiceList, DiagnosisList, FormClient, FormCompany, FormSignature, FormSignatureButton },
        data() {
            return {
                loading     : 1,
                updating    : 0,
                isEditable  : 1,
                user_id     : 0,
                client_id   : 0,
                page_id     : 0,
                rec         : {},
                created_at: '',
                updated_at: '',
                original_rec : {},
                signature_ids : [],
                original_signature_ids : [],
                showCreatePdfModal : false,
                api_signs_needed: null,
                signatures_info: null,
                company_insurance_settings: [],
                client: {},
                company: {},
                rows: [],
                level_id: 0,
                level: 'company',
                options: [],
                this_record_id : 0,
                router_push : 0,
                getCurrency,
                dayjs,
                //meta contains data from nested components that can be used to build the form
                //but is not used to populate the nested components
                //i.e meta.user = {id: 1, name: 'John', npi: '123456789'}
                //but we use rec.user_id to populate the user component
                meta: {cost: 0}
            }
        },
        methods: {
            async updateRecord(e) {
                if (!this.updating) {
                    this.updating = 1;
                    try {
                        e.preventDefault();
                    } catch (error) {
                        /**/
                    }

                    let xthis = await dryalex.form_data_update_record(this, e);
                    merge(this, xthis);
                    if (this.router_push) {
                        this.router_push = 1;
                        this.$forceUpdate();
                    }
                    this.original_signature_ids = this.signature_ids;
                }
            },
            async fail2sign() {
                this.showPdfModal(false);
            },
            async reloadData(signature) {
                this.showCreatePdfModal = false;
                this.signature_ids.push(signature);
            },
            showPdfModal(bool) {
                this.showCreatePdfModal = bool;
            },
            getClient(data){
                this.client = data;
            },
            getCompany(data){
                this.company = data;
            },
            //signatures that come from the FormSignatures component
            getSignatures(data) {
                this.signatures_info = data ? data : [];
            },
            modifyMeta(property, value){
                this.meta[property] = value;
                this.$set(this.meta, property, value);
                this.$forceUpdate();
                this.calculateCost();
            },
            modifyRecord(property, value) {
                this.$set(this.rec, property, value);
            },
            getMeta(property, value) {
                if(value) {
                    return this.meta?.[property]?.[value];
                } else {
                    return this.meta?.[property];
                }

            },
            async getCompanyInsuranceInfo() {
                const res = await this.$api.get(settings.getList('company', this.$store.state.user.company_id) + '/insurance');
                if(res?.data && Boolean(Array(res?.data))) {
                    this.company_insurance_settings = res.data;
                    // this.company_insurance_info = res.data.find((x) => x.setting === 'insurance_payers');
                }
            },
            companyNpi(){
              return this.company_insurance_settings.find((x) => x.setting === 'company_npi')?.value;
            },
            companyTaxId(){
                return this.company_insurance_settings.find((x) => x.setting === 'tax_id_ssn')?.value;
            },
            async init(){
                let xthis = await dryalex.form_data_init(this);
                merge(this, xthis);
                this.loading = 0;
                await this.getCompanyInsuranceInfo();
            },
            calculateCost(){
                let cost = 0;
                if(!this.loading) {
                    if (this.rec?.service_codes && this.rec?.service_codes.length > 0) {

                        this.rec['service_codes'].forEach(service => {
                            if(service.primary) {
                                try {
                                    let found = this.meta['service_code_options'].find(option => option.id === service.selected?.id);
                                    cost += found.cost ? found.cost : 0;

                                } catch (error) {
                                    //
                                }
                            }
                        });

                    }
                }
                this.meta.cost = cost;

            },

        },
        computed: {
            formattedDob() {
                return this.client && this.client.dob ? dayjs(this.client.dob.split('T')[0]).format('MM/DD/YYYY') : '';
            }
        },
        async created() {
            await this.init();

        },
        watch: {
            showCreatePdfModal(newVal) {
                if (window.innerWidth >= 768 && newVal == true) this.openModalOverlay();
                if (newVal == false) {
                    this.closeModalOverlay();
                }
            },
            'rec.service_codes': {
                handler(newVal, oldVal) {
                    this.calculateCost();
                },
                deep: true
            },

        }

    };
</script>